const items = [
  {
    title: "1981",
    // cardTitle: "Dunkirk",
    // cardSubtitle:
    //   "Men of the British Expeditionary Force (BEF) wade out to a destroyer during the evacuation from Dunkirk.",
    cardDetailedText: `Gondar Traditional group`,
  },
  {
    title: "1983",
    cardDetailedText: `National Theatre of Ethiopia`,
  },
  {
    title: "1987",
    cardDetailedText: `People to people cultural tour`,
  },
  {
    title: "1990",
    cardDetailedText: `Roha Band`,
  },
  {
    title: "1991",
    cardDetailedText: `Ethiopian Artist for Ethiopian Union`,
  },
  {
    title: "1992",
    cardDetailedText: `Demera Band Tour`,
  },
  {
    title: "1992",
    cardDetailedText: `People to people ||`,
  },
  {
    title: "1996",
    cardDetailedText: `Adwa Centennial`,
  },
  {
    title: "2005",
    cardDetailedText: `DVD release and Tamagn's return to the stage`,
  },
];

export default items;
