import React from "react";
import tamagn from "../assets/home/Testimony.jpg";

function TestimonyPage() {
  const listOfTestimony = [
    {
      url: "https://www.youtube.com/watch?v=qx2tx6n--Lo",
      image: "https://i.ytimg.com/vi/qx2tx6n--Lo/maxresdefault.jpg",
      title: "ስለታማኝ | ብ/ጄኔራል ካሳየ ጨመዳ | Tamagne Beyene |",
      description:
        "@Tamagne Mediaይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
    },
    {
      title:
        "ስለታማኝ | አርቲስት ፀሃዬ ዮሃንስ ስለታማኝ በየነ | Artist Tsehaye Yohannes About Tamagne Beyene",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/UWfmEfOP-Rg/maxresdefault.jpg",
      url: "https://youtu.be/UWfmEfOP-Rg",
    },
    {
      title:
        "ስለታማኝ | አርቲስት ፀጋዬ እሸቱ ስለታማኝ በየነ  | Tamagne Beyene | Tsegaye Eshetu",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/jDDQ6P2A9a8/maxresdefault.jpg",
      url: "https://youtu.be/jDDQ6P2A9a8",
    },
    {
      title: "ስለታማኝ | 🎷 ያሬድ ተፈራ ስለታማኝ በየነ  | Tamagne Beyene | Yared Tefera",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/eWRltSIet3M/maxresdefault.jpg",
      url: "https://youtu.be/eWRltSIet3M",
    },
    {
      title:
        "ስለታማኝ | አርቲስ ኤሊያስ ተባበል  ስለታማኝ በየነ  | Tamagne Beyene | Ellias Tebabale",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/Z7-WknfrGpQ/maxresdefault.jpg",
      url: "https://youtu.be/Z7-WknfrGpQ",
    },
    {
      title:
        "ስለታማኝ | ወዲ ሻምበል ዘብሔረ ኢትዮጵያ  ስለታማኝ በየነ  | Tamagne Beyene | Wedi Shambel",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/iq0usSWRP8k/maxresdefault.jpg",
      url: "https://youtu.be/iq0usSWRP8k",
    },
    {
      title:
        "ስለታማኝ | ነዋይ እና ፀሀዬ የህዝብ ለህዝብ ገጠመኛቸው | ስለታማኝ በየነ  | Tamagne Beyene | Tsehaye Yohannes | Neway Debebe",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/Y-AzDU1w45Q/maxresdefault.jpg",
      url: "https://youtu.be/Y-AzDU1w45Q",
    },
    {
      title: "ስለታማኝ ከአድናቂው አንደበት ከዶርዜ መንደር  | Tamagne Beyene |",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/SQW3pNz7S-A/maxresdefault.jpg",
      url: "https://youtu.be/SQW3pNz7S-A",
    },
    {
      title: "ስለታማኝ | እነአርቲስት ጋሽ ሰለሞን  | Tamagne Beyene |",
      description:
        " @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/FhjzwrIk-ks/maxresdefault.jpg",
      url: "https://youtu.be/FhjzwrIk-ks",
    },
    {
      title:
        "ስለታማኝ |  ፈቃዱ ተክለማሪያም | ፈለቀ አበበ | ጸጋየ ገብረመድህን  | feleke abebe | tsegaye gebremedhin",
      description:
        "  @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/B1zYUUa87Pc/maxresdefault.jpg",
      url: "https://youtu.be/B1zYUUa87Pc",
    },
    {
      title:
        "ስለታማኝ ኮሜዲያን ልመንስ | ታማኝ እና ቴዲ አፍሮ  | Tamagne Beyene | Comedian Limeneh | Teddy Afro",
      description:
        "  @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/I9TQ4KVBY98/maxresdefault.jpg",
      url: "https://youtu.be/I9TQ4KVBY98",
    },
    {
      title:
        "ስለታማኝ | የአዲስ አበባ ወጣቶች ስለታማኝ | ከ22 አመት በኋላ በአዲስ አበባ | Tamagne Beyene | Addis Ababa",
      description:
        "  @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/sLDBSC6n0VU/maxresdefault.jpg",
      url: "https://youtu.be/sLDBSC6n0VU",
    },
    {
      title: "ስለታማኝ - እዮብ ተወልደመድህን | Tamagne Beyene | Eyob TewoldeMedhin",
      description:
        "  @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/tdk3aVpHWuc/maxresdefault.jpg",
      url: "https://youtu.be/tdk3aVpHWuc",
    },
    {
      title:
        "ታማኝ  እና የታማኝ አድናቂ ልጅ ስም  [ ነገረ ስም ]  ስለ ታማኝ | Sile Tamagne | Tamagne Beyene",
      description:
        "  @TamagneShow ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ:: Our social Media Links Facebook ✓ https://bit.ly/3M6mvzg Instagram ✓ https://bit.ly/3kZNlgE Telegram ✓ https://t.me/TamagneMedia2021 Tiktok ✓ www.tiktok.com/@tamagnemedia =============================== Tamagne Beyene Official Accounts Facebook ✓ https://www.facebook.com/ArtistTamagne YouTube ✓ https://bit.ly/3wcpVK0 =============================== Fans Group and YouTube Channel YouTube ✓ https://bit.ly/3FHyEs4 Facebook Group ✓ https://www.facebook.com/groups/151303478435",
      image: "https://i.ytimg.com/vi/jNsYEnz0K0E/maxresdefault.jpg",
      url: "https://youtu.be/jNsYEnz0K0E",
    },
    {
      title:
        "ቀሲስ ደረጀ ስዩም | Kesis Dereje Seyoum |  ስለ ታማኝ | Sile Tamagne | Tamagne Beyene",
      description:
        "  @TamagneShow ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ:: Our social Media Links Facebook ✓ https://bit.ly/3M6mvzg Instagram ✓ https://bit.ly/3kZNlgE Telegram ✓ https://t.me/TamagneMedia2021 Tiktok ✓ www.tiktok.com/@tamagnemedia =============================== Tamagne Beyene Official Accounts Facebook ✓ https://www.facebook.com/ArtistTamagne YouTube ✓ https://bit.ly/3wcpVK0 =============================== Fans Group and YouTube Channel YouTube ✓ https://bit.ly/3FHyEs4 Facebook Group ✓ https://www.facebook.com/groups/151303478435",
      image: "https://i.ytimg.com/vi/RhCGZKjgP8A/maxresdefault.jpg",
      url: "https://youtu.be/RhCGZKjgP8A",
    },
    {
      title:
        "ስለታማኝ ኮሜዲያን ደረጀ እና ጥላሁን እልፍነህ | Tamagne Beyene | Comedian Dereje Haile | Tilahun Elfineh",
      description:
        "  @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/SIVmQKIrsTw/maxresdefault.jpg",
      url: "https://youtu.be/SIVmQKIrsTw",
    },
    {
      title: "አርቲስት ተሾመ ወልዴ ስለታማኝ | @TamagneShow",
      description:
        "  @Tamagne Media ይህ የታማኝ በየነ አድናቂዎች ግሩፕ የዩቲዩብ ቻናል ነው :: እርስዎ እጅ ላይ ስለታማኝ ምን አይነት ቪዲዮ አለ? ወይም እርስዎ ስለታማኝ በየነ ያለዎትን መልዕክት በመላክ ወዳጅ ይሁኑ::",
      image: "https://i.ytimg.com/vi/TTg-GKHP5zM/maxresdefault.jpg",
      url: "https://youtu.be/TTg-GKHP5zM",
    },
  ];

  return (
    <div className="grid md:grid-cols-3">
      <div
        className="w-full bg-fit bg-fixed bg-no-repeat "
        style={{
          backgroundImage: `url(${tamagn})`,
        }}
      ></div>
      <div className="col-span-2">
        {" "}
        <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-2 m-10">
          {listOfTestimony.map((each) => (
            <a
              href={each.url}
              target="blank"
              className="rounded-md shadow-md bg-gray-800 m-2 overflow-hidden"
            >
              {" "}
              <img
                className="w-full h-40 overflow-hidden  object-cover"
                src={each.image}
                alt=""
              />
              <p className="px-3 h-7 overflow-hidden font-semibold">
                {each.title}
              </p>
              <p className="px-3 text-sm mb-3 h-16 overflow-hidden">
                {each.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TestimonyPage;
