import React from "react";
import HomeBg from "../assets/home-pic.jpg";

function HomePage() {
  return (
    <div
      className="w-full h-full bg-gray-600 text-white rounded-md shadow-xl shadow-black bg-cover "
      style={{ backgroundImage: `url(${HomeBg})` }}
    >
      {" "}
    </div>
  );
}

export default HomePage;
