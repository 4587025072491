import React from "react";
import one from "../assets/recommendation/photo_2023-06-04 21.56.59.jpeg";
import two from "../assets/recommendation/photo_2023-06-04 21.56.57.jpeg";
import three from "../assets/recommendation/photo_2023-06-04 21.56.54.jpeg";
import four from "../assets/recommendation/photo_2023-06-04 21.56.48.jpeg";
import five from "../assets/recommendation/photo_2023-06-04 21.56.56.jpeg";
import six from "../assets/recommendation/DFW.jpg";
import seven from "../assets/recommendation/Demtsachin Yesema.jpg";
import eight from "../assets/recommendation/DallAS.jpg";
import nine from "../assets/recommendation/[edited]-ETHIOPIAN-mUSLIMS.jpg";
import ten from "../assets/recommendation/[Edited]-People-to-People.jpg";
import eleven from "../assets/recommendation/[edited]-pAGUME.jpg";
import twelv from "../assets/recommendation/[edited]-Atlanta-Meqdem.jpg";
import thirteen from "../assets/recommendation/[Edited] Chicago-ESAT-Supporter.jpg";
import forteen from "../assets/croped/Abisenya.jpg";
import fifteen from "../assets/croped/air-force.jpg";
import sixteen from "../assets/croped/Araya-Sew.jpg";
import seventeen from "../assets/croped/bego-sew.jpg";
import eighteen from "../assets/croped/boston.jpg";
import nineteen from "../assets/croped/ESFNA.jpg";
import twenty from "../assets/croped/Hizb-Le-Hizb.jpg";
import twentyone from "../assets/croped/photo_2023-06-04 21.55.53.jpeg";
import twentytwo from "../assets/croped/photo_2023-06-04 21.56.16.jpeg";
import twentythree from "../assets/croped/photo_2023-06-04 21.56.19.jpeg";
import twentyfour from "../assets/croped/photo_2023-06-04 21.56.29.jpeg";
import twentyfive from "../assets/croped/photo_2023-06-04 21.56.39.jpeg";
import twentysix from "../assets/croped/photo_2023-06-04 21.56.43.jpeg";
import twentyseven from "../assets/croped/photo_2023-06-04 21.56.50.jpeg";
import twentyeight from "../assets/croped/photo_2023-06-04 21.56.52.jpeg";
import twentynine from "../assets/croped/photo_2023-06-04 21.57.01.jpeg";
import thirty from "../assets/croped/seed.jpg";
import thirtyone from "../assets/croped/st-lewis-m.jpg";
import thirttwo from "../assets/croped/ቀኃሥ-የጦር-አካዳሚ-.jpg";

function AcknowledgementPage() {
  const listOfRecognition = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelv,
    thirteen,
    forteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
    twentyone,
    twentytwo,
    twentythree,
    twentyfour,
    twentyfive,
    twentysix,
    twentyseven,
    twentyeight,
    twentynine,
    thirty,
    thirtyone,
    thirttwo,
  ];

  return (
    <div className="grid grid-cols-1  lg:grid-cols-3 gap-2 m-10">
      {listOfRecognition.map((each) => (
        <div className="rounded-md  m-2 overflow-hidden">
          {" "}
          <img className="w-full  overflow-hidden " src={each} alt="" />
        </div>
      ))}
    </div>
  );
}

export default AcknowledgementPage;
