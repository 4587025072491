import React, { useState } from "react";
import { Chrono } from "react-chrono";
import data from "../data/data";

function HorizontalTimline() {
  return (
    <div className="flex justify-center">
      <div className="w-[83vw] mb-40 shadow-lg p-6 rounded-md bg-slate-700/50 ">
        <Chrono
          items={data}
          mode="HORIZONTAL"
          showAllCardsHorizontal
          cardWidth={450}
          cardHeight={50}
          contentDetailsHeight={100}
          fontSizes={{
            title: "1rem",
          }}
          hideControls={true}
          slideShow={false}
          theme={{
            primary: "#008001",
            secondary: "#ffc107",
            cardBgColor: "#394a60",

            cardDetailsColor: "white",
            cardTextColor: "white",
            titleColor: "white",
            titleColorActive: "red",
          }}
        />
      </div>
    </div>
  );
}

export default HorizontalTimline;
