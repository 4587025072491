import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactPage from "./ContactPage";
import NewsPage from "./NewsPage";
import GallaryPage from "./GallaryPage";
import RecognitionsPage from "./RecognitionsPage";
import AcknowledgementPage from "./AcknowledgementPage";
import HomePage from "./HomePage";
import BioPage from "./BioPage";
import TestimonyPage from "./TestimonyPage";

function Home() {
  const navigation = {
    main: [
      { name: "Home", href: "/home" },
      { name: "Contact", href: "/contact" },
    ],
    topnav: [
      { name: "News", href: "/news" },
      { name: "Bio", href: "/bio" },
      { name: "Gallery", href: "/gallery" },
      { name: "Recognitions", href: "/recognition" },
      { name: "Acknowledgement", href: "/acknowledgement" },
      { name: "Testimony", href: "/testimony" },
    ],
    all: [
      { name: "Home", href: "/home" },
      { name: "Contact", href: "/contact" },
      { name: "News", href: "/news" },
      { name: "Bio", href: "/bio" },
      { name: "Gallery", href: "/gallery" },
      { name: "Recognitions", href: "/recognition" },
      { name: "Acknowledgement", href: "/acknowledgement" },
      { name: "Testimony", href: "/testimony" },
    ],
  };

  let { hashTag } = useParams();
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (hashTag && hashTag !== selected) {
      setSelected(hashTag);
    } else if (!hashTag) {
      setSelected("home");
    }
    setLoading(false);
  }, [hashTag, selected]);

  const handlePageChange = (newSelected) => {
    setSelected(newSelected);
  };

  if (loading) {
    return <div>Loading.. </div>;
  }

  return (
    <div className="min-w-screen  min-h-[80vh] flex flex-col justify-center items-center bg-gray-800">
      <div className=" flex md:hidden w-full overflow-auto ">
        {navigation.all.map((each) => (
          <a
            key={each.name}
            href={each.href}
            className={`mx-2 ${
              each.href === window.location.pathname
                ? "bg-yellow-400"
                : "bg-white"
            } rounded-t-md px-3 hover:bg-yellow-500 duration-300  text-black`}
            onClick={() => handlePageChange(each.href.substring(1))}
          >
            {each.name}
          </a>
        ))}
      </div>
      <div className="flex flex-col w-full md:h-[90vh] h-[80vh] md:px-20  justify-center items-center">
        <div className="w-full justify-end mr-10 hidden md:flex">
          {navigation.topnav.map((each) => (
            <a
              key={each.name}
              href={each.href}
              className={`mx-2 ${
                each.href === window.location.pathname
                  ? "bg-yellow-400"
                  : "bg-white"
              } rounded-t-md px-3 hover:bg-yellow-500 duration-300 text-black`}
              onClick={() => handlePageChange(each.href.substring(1))}
            >
              {each.name}
            </a>
          ))}
        </div>
        <div className="flex w-full h-[80vh]  rounded-md">
          <div className="w-10 h-10 rotate-90 hidden md:flex">
            {navigation.main.map((each) => (
              <a
                key={each.name}
                href={each.href}
                className={`mx-2 ${
                  each.href === window.location.pathname
                    ? "bg-yellow-400"
                    : "bg-white"
                } rounded-b-md px-3 hover:bg-yellow-500 duration-300  text-black`}
                onClick={() => handlePageChange(each.href.substring(1))}
              >
                <p className="rotate-180 pb-2"> {each.name}</p>
              </a>
            ))}
          </div>
          <div className="w-full h-full overflow-auto bg-gray-900 text-white rounded-md shadow-xl mx-26 ">
            {selected === "contact" ? (
              <ContactPage />
            ) : selected === "news" ? (
              <NewsPage />
            ) : selected === "gallery" ? (
              <GallaryPage />
            ) : selected === "recognition" ? (
              <RecognitionsPage />
            ) : selected === "acknowledgement" ? (
              <AcknowledgementPage />
            ) : selected === "bio" ? (
              <BioPage />
            ) : selected === "testimony" ? (
              <TestimonyPage />
            ) : selected === "home" ? (
              <HomePage />
            ) : (
              <div className="w-full h-full bg-gray-600 text-white rounded-md shadow-xl shadow-black bg-cover "></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
