import React from "react";
import tamagn from "../assets/home/recognition.jpg";

function RecognitionsPage() {
  const listOfRecognition = [
    {
      title: 'ከብ/ጄኔራል ኃይሉ በረዋቅ  "ዋ! ስንቱን ላንሳው" መጽሀፍ ስጦታ | Tamagne Beyene',
      description:
        '#tamagne #tamagnbeyene #ethiopia #vlog ብ/ጄኔራል ኃይሉ በረዋቅ "ዋ! ስንቱን ላንሳው" ከ4ኛ ክፍለ ጦር እስከ ሰሜን ግንባር የተሰኜ መጽሀፋቸውን በአካል ለአርቲስት ታማኝ በዬነ አበርክተዋል። ====== Artist Tamage Beyene Tamagne Beyene was born to Woizero Mamite Bitew and Grazmach Beyene Wondifraw in Chilga, Northern Gondar, Ethiopia. Then we drew a blank. Even his age proved to be a moving target. Tamagne claims that numerous times in his attempt to break onto the stage he was told he was “far too young” to perform, for “professional” reasons he had made it a point to never divulge his age. True many years have p...assed since this was the case, but as a matter of principle, his age remains a closely held secret, and those in the know have been sworn to secrecy. What is no secret is the huge comedic talent that is Tamagne Beyene. .... While with Gondar Traditional Group, Tamagne soon realized...',
      image: "https://i.ytimg.com/vi/-v5ggKaK1_c/maxresdefault.jpg",
      url: "https://youtu.be/-v5ggKaK1_c",
    },
    {
      title:
        "ከሙስሊም መፍትሄ አፈላላጊ ኮሚቴ ለታማኝ በየነ በየሂደቱ ላደረገው ትልቅ ድጋፍ እና የዜግነት ግዴታውን ስለተወጣ የተበረከተ ስጦታ | Tamagne Beyene",
      description:
        "#tamagne #tamagnbeyene #ethiopia #vlog ከሙስሊም መፍትሄ አፈላላጊ ኮሚቴ ለታማኝ በየነ በየሂደቱ ላደረገው ትልቅ ድጋፍ እና የዜግነት ግዴታውን ስለተወጣ የተበረከተ ስጦታ ======= Artist Tamage Beyene Tamagne Beyene was born to Woizero Mamite Bitew and Grazmach Beyene Wondifraw in Chilga, Northern Gondar, Ethiopia. Then we drew a blank. Even his age proved to be a moving target. Tamagne claims that numerous times in his attempt to break onto the stage he was told he was “far too young” to perform, for “professional” reasons he had made it a point to never divulge his age. True many years have p...assed since this was the case, but as a matter of principle, his age remains a closely held secret, and those in the know have been sworn to secrecy. What is no secret is the huge comedic talent that is Tamagne Beyene. .... While with Gondar Traditional Group, Tamagne soon realized that his forte...",
      image: "https://i.ytimg.com/vi/omMIaDHgl_Q/maxresdefault.jpg",
      url: "https://youtu.be/omMIaDHgl_Q",
    },
    {
      title:
        "የአቢሲኒያ ሽልማት የከፍተኛ ክብር የወርቅ ኒሻን እና የዲፕሎማ ሽልማት ፣ ጳጉሜ 2011  | Abysinia Award",
      description:
        "#tamagne #tamagnbeyene #ethiopia #vlog የአቢሲኒያ ሽልማት የከፍተኛ ክብር ሽልማት ዘርፍ የህይወት ዘመን የሰብዓዊ መብት ተሟጋች ፣ የፖለቲካ ተንታኝ የ2011 ዓ.ም የከፍተኛ ክብር የወርቅ ኒሻን እና የዲፕሎማ ሽልማት ፣ ጳጉሜ 2011 አዲስ አበባ ኢትዮጵያ ======================== Artist Tamage Beyene Tamagne Beyene was born to Woizero Mamite Bitew and Grazmach Beyene Wondifraw in Chilga, Northern Gondar, Ethiopia. Then we drew a blank. Even his age proved to be a moving target. Tamagne claims that numerous times in his attempt to break onto the stage he was told he was “far too young” to perform, for “professional” reasons he had made it a point to never divulge his age. True many years have p...assed since this was the case, but as a matter of principle, his age remains a closely held secret, and those in the know have been sworn to secrecy. What is no secret is the huge comedic talent that is Tamagne...",
      image: "https://i.ytimg.com/vi/XEr4XL1UmDg/maxresdefault.jpg",
      url: "https://youtu.be/XEr4XL1UmDg",
    },
    {
      title:
        "የ2011 በጎ ሰው ሽልማት ለኢትዮጵያ እድገት አስተዋጾ ያደረጉ ዘርፍ ሽልማት | Tamagne Beyene",
      description:
        "#tamagne #tamagnbeyene #ethiopia #vlog የ2011 በጎ ሰው ሽልማት ለኢትዮጵያ እድገት አስተዋጾ ያደረጉ ዘርፍ ሽልማት | Tamagne Beyene ====== Artist Tamage Beyene Tamagne Beyene was born to Woizero Mamite Bitew and Grazmach Beyene Wondifraw in Chilga, Northern Gondar, Ethiopia. Then we drew a blank. Even his age proved to be a moving target. Tamagne claims that numerous times in his attempt to break onto the stage he was told he was “far too young” to perform, for “professional” reasons he had made it a point to never divulge his age. True many years have p...assed since this was the case, but as a matter of principle, his age remains a closely held secret, and those in the know have been sworn to secrecy. What is no secret is the huge comedic talent that is Tamagne Beyene. .... While with Gondar Traditional Group, Tamagne soon realized that his forte was connecting to...",
      image: "https://i.ytimg.com/vi/1r26iWvihb4/maxresdefault.jpg",
      url: "https://youtu.be/1r26iWvihb4",
    },
    {
      title: "ከኢትዮጵያን እናድን አንድነት ማህበር በእስራኤል የተበረከተ ሽልማት",
      description: "ከኢትዮጵያን እናድን አንድነት ማህበር በእስራኤል የተበረከተ ሽልማት",
      image: "https://i.ytimg.com/vi/xEjlt2x4WZw/maxresdefault.jpg",
      url: "https://youtu.be/xEjlt2x4WZw",
    },
    {
      title: "ከእስራኤል የኢሳት ግብረሃይ የተበረከት ሽልማት",
      description: "ከእስራኤል የኢሳት ግብረሃይ የተበረከት ሽልማት",
      image: "https://i.ytimg.com/vi/9xImharMKWo/maxresdefault.jpg",
      url: "https://youtu.be/9xImharMKWo",
    },
    {
      title: "ከኢሳት አምስተርዳም ቤተሰቦች ሽልማት ለታማኝ | ESAT Amsterdam",
      description: "ከኢሳት አምስተርዳም ቤተሰቦች ሽልማት ለታማኝ | ESAT Amsterdam",
      image: "https://i.ytimg.com/vi/5yvl6833mz8/maxresdefault.jpg",
      url: "https://youtu.be/5yvl6833mz8",
    },
    {
      title: "በዳላስ ድምጻችን ይሰማ አስተዋጾ ላደረጉ የእውቅና እና የሽልማት ዝግጅት",
      description: "በዳላስ ድምጻችን ይሰማ አስተዋጾ ላደረጉ የእውቅና እና የሽልማት ዝግጅት",
      image: "https://i.ytimg.com/vi/g_6QGaRoi_w/maxresdefault.jpg",
      url: "https://youtu.be/g_6QGaRoi_w",
    },
    {
      title: "Army",
      description: "",
      image: "https://i.ytimg.com/vi/hj7k20lCIuY/maxresdefault.jpg",
      url: "https://youtu.be/hj7k20lCIuY",
    },
    {
      title: "Boston SEED ( Society of Ethiopians Established in Diaspora)",
      description:
        "Boston SEED ( Society of Ethiopians Established in Diaspora)",
      image: "https://i.ytimg.com/vi/MIHmK4b2lIA/maxresdefault.jpg",
      url: "https://youtu.be/MIHmK4b2lIA",
    },
    {
      title:
        '"ጽኑ ኢትዮጵያዊነትህን ላንዳፍታ እንኳን የሚነቀንቅ ምንም ኃይል ሊገጥምህ አይገባም " | Charlotte, North Carolina',
      description:
        '"ጽኑ ኢትዮጵያዊነትህን ላንዳፍታ እንኳን የሚነቀንቅ ምንም ኃይል ሊገጥምህ አይገባም " | Charlotte, North Carolina',
      image: "https://i.ytimg.com/vi/8oxpBkPRs7M/maxresdefault.jpg",
      url: "https://youtu.be/8oxpBkPRs7M",
    },
  ];
  return (
    <div className="grid  md:grid-cols-4">
      <div
        className="w-full bg-contain bg-fixed bg-no-repeat "
        style={{
          backgroundImage: `url(${tamagn})`,
          backgroundPosition: "left",
        }}
      ></div>
      <div className="col-span-3">
        <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-2 m-10">
          {listOfRecognition.map((each) => (
            <a
              href={each.url}
              target="blank"
              className="rounded-md shadow-md bg-gray-800 hover:bg-gray-700 duration-300 m-2 overflow-hidden"
            >
              {" "}
              <img
                className="w-full h-40 overflow-hidden"
                src={each.image}
                alt=""
              />
              <p className="px-3 h-7 overflow-hidden font-semibold">
                {each.title}
              </p>
              <p className="px-3 text-sm mb-3 h-16 overflow-hidden">
                {each.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RecognitionsPage;
