import React from "react";
import tamagn from "../assets/home/bio.jpg";

function BioPage() {
  return (
    <div className="grid md:grid-cols-3 bg-white">
      <div
        className="w-full bg-fit bg-fixed bg-no-repeat "
        style={{
          backgroundImage: `url(${tamagn})`,
        }}
      ></div>
      <div className="flex col-span-2 flex-col  bg-cover bg-white text-black w-full h-full overflow-auto md:px-28 px-5 py-10">
        <span className="text-lg font-bold">Tamagne Beyene Biography</span>{" "}
        <br />
        <br /> Human Rights Activist, Political Commentator, Talk Show Host,
        Comedian <br />
        <span className="text-lg font-bold"> Synopsis </span>
        <br /> A multi-talented performer, human rights activist and political
        commentator, Tamagne Beyene was born , in a small town called Chilga in
        Gonder, Ethiopia. At a very young age, he moved to Addis Ababa and
        became popular performing at the legendary National Theater, Beherawi
        Theater.
        <br />
        Tamagne’s rise to national prominence as a human rights activist begun
        in 1991 when he openly and fearlessly declared his opposition to the
        Ethiopian Regime’s ethnocentric governance and human rights violations.
        As the regime became more brutal, Tamagne was forced to flee to exile.
        <br /> Among Tamagne’s numerous accomplishments are his leadership role
        in the creation numerous civic, human rights, and community
        organizations to serve the needs and interest of Ethiopians in the
        Diaspora and the Ethiopian people in the country. The Ethiopian Heritage
        Society in North America (EHSNA), Global Alliance for the Rights of
        Ethiopians (GARE) of which he is currently the Chairperson; his pivotal
        role in the formation, and management of the Ethiopian Satellite
        Television and Radio (ESAT); his role in the creation of the Ethiopian
        Artists Association, which he chaired for six years, and many, many more
        are among the many that deserve mention.
        <br /> Through his activism, Tamagne has helped countless civic,
        religious, humanitarian, cultural and political organizations get their
        messages out to the public. He has been the most prominent voice and a
        leading champion of these institutions. He has done more than anyone in
        raising funds to promote the struggle to bring about freedom, justice
        and the rule of law to our beloved country.
        <br />
        Early Years
        <br /> Tamang Beyene was born in Gonder, Ethiopia in a small city called
        Chilga to his father Giazemach Beyene Wondifraw and to his mother Mamite
        Bitew.
        <br /> At the age of 19, Tamagn moved to Addis Ababa and became popular
        at the legendary Beherawi Theater where he was able to meet, learn from
        and perform with legendary Ethiopian artists in the various disciplines
        of music and art.
        <br /> His time at the theatre revealed that he was a multi-talented
        young man who was able to easily grasp various artistic disciplines.
        Within a short period of time, he was able to master dancing, acting,
        singing, choreography, playing various musical instruments, and
        performing as a stand-up comedian. He had a great command of the stage
        and his second to none performance caught the eye of the public which
        soon realized his exceptional talent. At a very young age, Tamagne
        became the most sought-after master of ceremony in the country.
        <br /> He was soon chosen among a few groups of people as a cultural
        ambassador to represent Ethiopia in a worldwide tour that was called
        Hizb Le Hizb (People to People). This tour would define Tamagn as a
        performer par excellence introducing him to a wider audience beyond
        Ethiopia.
        <br /> During Hizb Le Hizb’s tour, as fate would have it, the only
        drummer in the band went “missing” after the tour started. This
        unfortunate incident threatened to jeopardize the entire tour had
        Tamagne not stepped in to fill the drummer’s spot. Upon returning back
        to Ethiopia, he started touring throughout Ethiopia with Roha and Demera
        Bands further propelling his career. <br /> Tamagne’s exceptional
        ability to understand and process sensitive social, cultural, religious
        and political issues of our time and to address them in public, in a
        manner that is not contentious, would later serve him in creating his
        own style of stand-up comedy. He became the first Ethiopian stand-up
        comedian to incorporate entertainment with comedy and political satire.
        Tamagne’s elegant and dignified style, coupled with his affable
        demeanor, has enabled him to integrate his role as a social-activist
        with his talent as an entertainer and bring serious, existential issues
        such as ethnic-governance, human rights, etc. on to the stage.
        <br /> National Prominence <br /> Tamagn has always spoken against human
        rights violations in Ethiopia. In the early 90s, after the TPLF took
        power, he became even more vocal after seeing the abuses Ethiopians were
        enduring under the current regime in Ethiopia. Whenever he got to
        perform on various occasions and platforms, he courageously and
        articulately spoke against the inter-ethnic conflicts fomenting in many
        parts of the country as a result of the regime’s ethnic politics.
        <br /> Tamagne tirelessly promotes tolerance, peace, and unity among
        Ethiopians... On many occasions, he made a mockery of ethnic-federalism
        in one of the mega rallies attended by the leadership of the regime. As
        a result, he soon became the target of the regime’s security apparatus.
        He was harassed, arrested and tortured more than a few times before
        deciding to go into exile. In exile, he continues to speak for the tens
        of millions of Ethiopians that have been silenced by the regime in
        Ethiopia.
        <br /> Legacy <br /> The biblical value of being “thy brother’s keeper”
        was instilled in Tamagne at a very early age. His unwavering commitment
        to justice and fair play, and his dedication to his country came from
        his family background. His father, Kegazemach Beyene Wondifraw, and his
        uncles, as well as his grandparents, served the Ethiopian people during
        the resistant waged against the fascist Italian invasion and occupation
        of Ethiopia. This legacy of patriotism, the love of country, and serving
        others was instilled in him at a very early age.
        <br /> Tamagne’s accomplishments consist of a plethora of contributions
        made at every level of Ethiopian society ranging from local,
        organizational, community and national levels. To name but a few,
        <br /> He has helped found and chaired the Ethiopian artists association
        from 1998-2004;
        <br /> He was one of the founders and the face of the Ethiopian
        Television Network (ETN), which was the first Ethiopian TV Network in
        America that run 24 hours a day, seven days a week;
        <br /> He is a founding member of Ye Jegnoch Meshet, an organization
        that was established to award Ethiopian heroes and prominent figures.
        The organization has honored individuals such as Gen. Jegama Kelo, Gen
        Kasaye Chemeda, Gen. Tesefaye Habtemariam, Capitain Mamo Hailemarima and
        many more; <br /> He has made more than 12 documentaries featuring
        distinguished Ethiopians who have contributed to their country with
        unique and rare footages. To name a few, the legendary artist Tilahun
        Gessese, War hero Gen.Legese Tefera, and the prominent artist Tesefaye
        Lema and many more. These documentaries serve to instruct and enlighten
        the new generation to draw lessons from those great Ethiopians;
        <br /> He has also been at the forefront of fundraising and organizing
        for various prominent figures to cover their medical expenses. To name a
        few, Artist Getamesay Abebe and Wegayehu Degenetu received medical
        attention in India and, Artist Tamerat Mola were brought to US.
        <br /> Tamagn facilitated the journey of Shambel Guta Dinka to South
        Africa to attend Nelson Mandel’s burial ceremony. Shambel Guta is one of
        the unsung heroes of Ethiopia who saved Mandela from assassination
        attempt while he was in Ethiopia during the 1960s <br /> Tamagn also
        played a key role in coordinating and rising fund that helped to pay off
        the mortgage of Saint Gabriel Cathedral. <br /> Tamagne is a founding
        member of the Ethiopian heritage society in North America (EHSNA). The
        organization is devoted to promoting Ethiopian culture through
        literature, music and the arts, enriching the lives of Ethiopian and
        Ethiopian American’s in a new adopted countries and promoting cross
        cultural understanding to be part of the cultural mosaic of North
        America. <br /> Tamagn played a key role in the establishment of
        Ethiopian Satellite Television (ESAT) where he still works tirelessly
        campaigning and raising the funds needed to run the organization. He has
        travelled throughout the world meeting Ethiopians and bringing them
        together to support ESAT, the voice to millions of voiceless Ethiopians.
        <br /> In addition, he is a founding member and the current chairperson
        of the Global Alliance for the Rights of Ethiopians (GARE). GARE was
        established in the aftermath of the torture and mass persecution of
        Ethiopian migrant workers in Saudi Arabia. GARE was able to mobilize
        tens of thousands of Ethiopians around the world to demand justice and
        speedy repatriation of the Ethiopian workers back to their country. In a
        matter of weeks, the Saudi government was forced to conduct one of the
        speediest mass repatriation in recent history. In addition to organizing
        protest against the injustice committed against Ethiopians in many
        cities around the world, GARE also raised and donated $150,000 to the
        International Organization for Migration (IOM) which used the money to
        help tens of thousands of Ethiopians settle back in their homeland.
        <br /> Today, Tamagn is still an ardent activist who has devoted his
        life to promote justice, freedom and the rule of law for all Ethiopians.
        He is respected and loved by Ethiopians from all walks of life. His
        unyielding dedication to human rights, freedom, and community building
        has made him a beloved asset to Ethiopians throughout the world. Tamagne
        is a visionary leader deeply committed to achieving social justice for
        all.
      </div>
    </div>
  );
}

export default BioPage;
