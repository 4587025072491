import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import eight1 from "../assets/80's/336598757_185116047282740_3467189125589755581_n.jpg";
import eight2 from "../assets/80's/342174057_181577844786825_6103707272103988425_n.mp4.00_00_00_0.Still001.jpg";
import eight3 from "../assets/80's/342724356_611182460913464_1654170304606832202_n.mp4.00_00_00_0.Still001.jpg";
import eight4 from "../assets/80's/342733630_532879185675297_6405760832065699427_n.mp4.00_00_00_0.Still001.jpg";
import nine1 from "../assets/90's/335949456_784094633277874_5003485720183493924_n.jpg";
import nine2 from "../assets/90's/336600608_223300413630464_8327904993430383518_n.jpg";
import nine3 from "../assets/90's/336602877_545821520807143_7545394040484726580_n.jpg";
import nine4 from "../assets/90's/336602881_737009761302514_8848368749248503486_n.jpg";
import nine5 from "../assets/90's/340622503_606599534715200_4777103344056663017_n.jpg";
import nine6 from "../assets/90's/341099597_566763921967432_123407023302361918_n.jpg";
import nine7 from "../assets/90's/341180860_195927506540978_137050507747033879_n.jpg";
import nine8 from "../assets/90's/341200482_188398794009912_6292017006561638785_n.jpg";
import nine9 from "../assets/90's/341237063_954991525694798_3676788477379389974_n.jpg";
import nine10 from "../assets/90's/341240052_611838717247286_8561069351941868249_n.jpg";
import nine11 from "../assets/90's/341242708_778527353517558_3846967906659277498_n.jpg";
import nine12 from "../assets/90's/341249496_756420586097273_8919815180030498615_n.jpg";
import nine13 from "../assets/90's/341253820_1287043285502308_8462084583891755856_n.jpg";
import nine14 from "../assets/90's/341259406_1361174697787872_2444202713945079856_n.jpg";
import nine15 from "../assets/90's/341262626_159199853469857_4767254455588370376_n.jpg";
import nine16 from "../assets/90's/341277202_1958037507884142_4936341958508010233_n.jpg";
import nine17 from "../assets/90's/341283813_941181453677375_1833602687150660986_n.jpg";
import tewnty1 from "../assets/2000's/324070920_238302508708917_1833272488708477528_n.jpg";
import tewnty2 from "../assets/2000's/330251848_581108213796825_395121148810685190_n.jpg";
import tewnty3 from "../assets/2000's/341099598_790555828806953_3333063276296924070_n.jpg";
import tewnty4 from "../assets/2000's/341101513_632717138872398_2757416528899615564_n.jpg";
import tewnty5 from "../assets/2000's/341130270_605047841543485_2664216903057548404_n.jpg";
import tewnty6 from "../assets/2000's/341143059_759109448919190_4019116533706355418_n.jpg";
import tewnty7 from "../assets/2000's/341179814_788233786154764_5905407563481014516_n.jpg";
import tewnty8 from "../assets/2000's/341198053_624266915887385_2621508860459505417_n.jpg";
import tewnty9 from "../assets/2000's/341207991_792426861919682_9080519016145709656_n.jpg";
import tewnty10 from "../assets/2000's/341210067_728267402335357_6083231838414838076_n.jpg";
import tewnty11 from "../assets/2000's/341235497_3317787648484339_7116915083353547587_n.jpg";
import tewnty12 from "../assets/2000's/341247646_217283340923257_5755658626693306075_n.jpg";
import tewnty13 from "../assets/2000's/341259658_992332121958494_1789338498841648987_n.jpg";
import tewnty14 from "../assets/2000's/341273791_768959168076946_1423510906459804382_n.jpg";
import tewnty15 from "../assets/2000's/341278457_776742360416068_8961815375301057238_n.jpg";
import tewnty16 from "../assets/2000's/341314138_757571432430721_4375791249679066576_n.jpg";

function GallaryPage() {
  var eightis = [eight1, eight2, eight3, eight4];
  var nintis = [
    nine1,
    nine2,
    nine3,
    nine4,
    nine5,
    nine6,
    nine7,
    nine8,
    nine9,
    nine10,
    nine11,
    nine12,
    nine13,
    nine14,
    nine15,
    nine16,
    nine17,
  ];
  var twentis = [
    tewnty1,
    tewnty2,
    tewnty3,
    tewnty4,
    tewnty5,
    tewnty6,
    tewnty7,
    tewnty8,
    tewnty9,
    tewnty10,
    tewnty11,
    tewnty12,
    tewnty13,
    tewnty14,
    tewnty15,
    tewnty16,
  ];

  return (
    <div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(55, 81, 55)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(55, 64, 81)" }}
          date="80's"
          iconStyle={{ background: "green", color: "#fff" }}
          icon={
            <div className="flex w-full h-full justify-center items-center">
              80's
            </div>
          }
        >
          <div className="flex flex-col bg-gray-700">
            {eightis.map((each) => (
              <img
                src={each}
                alt="80's"
                className="overflow-hidden rounded-md mb-2 py-2"
              />
            ))}
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="90's"
          iconStyle={{ background: "yellow", color: "#000" }}
          contentStyle={{ background: "rgb(80, 81, 55)", color: "#fff" }}
          icon={
            <div className="flex w-full h-full justify-center items-center">
              90's
            </div>
          }
        >
          <div className="flex flex-col bg-gray-700">
            {nintis.map((each) => (
              <img
                src={each}
                alt="90's"
                className="overflow-hidden rounded-md mb-2 py-2"
              />
            ))}
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2000's"
          iconStyle={{ background: "red", color: "#fff" }}
          contentStyle={{ background: "rgb(81, 55, 55)", color: "#fff" }}
          icon={
            <div className="flex w-full h-full justify-center items-center">
              2000's
            </div>
          }
        >
          <div className="flex flex-col bg-gray-700">
            {twentis.map((each) => (
              <img
                src={each}
                alt="2000's"
                className="overflow-hidden rounded-md mb-2 py-2"
              />
            ))}
          </div>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default GallaryPage;
