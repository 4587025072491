import React from "react";
import tamagn from "../assets/home/News.jpg";

function NewsPage() {
  const liftOfNews = [
    {
      title: "Right activist, Artist Tamagn Beyene returns home after 22 years",
      description:
        "Human right activist and artists Tamagn Beyene returned home after he spent 22 years in exile in USA. He was welcomed by higher government officials and his fans when he arrived in Addis Ababa earlier",
      image: "https://waltainfo.com/wp-content/uploads/images/tamagn34.jpg",
      url: "https://waltainfo.com/41144/",
    },
    {
      title: "VOA Amharic on Facebook",
      description:
        "አርቲስት ታማኝ በየነ ቅዳሜ ኢትዮጵያ ሊገባ ነው #Ethiopia #TamagneBeyene #VOAAmharic #GabinaVOA በኢትዮጵያ መንግሥት ላይ ተቃውሞን በማሰማት፣ ትችት በመሰንዘርና...",
      image: "https://facebook.com/security/hsts-pixel.gif?c=3.2.5",
      url: "https://m.facebook.com/VOAAmharic/posts/2081445718553295/",
    },
    {
      title:
        '"ካገሬ የወጣሁት ከፍቶኝ ተገፍቼ ነው። ስመለስ ግን ቂም ይዤ አይደለም" ታማኝ በየነ | DW | 01.09.2018',
      description:
        'በኢትዮጵያ ፖለቲካ ለውጥ እንዲኖር ለረዥም አመታት ይወተውት የነበረው ታማኝ በየነ ከ22 አመታት በኋላ ወደ አገሩ ተመልሷል። ታማኝ "ለእኔ መምጣት ህይወት የከፈሉ አሉ፤ የተገረፉ አሊሉ፤ መከራ የተቀበሉ አሉ" ሲል ተደምጧል።',
      image: "https://static.dw.com/image/45320935_6.webp",
      url: "https://www.dw.com/am/%E1%8A%AB%E1%8C%88%E1%88%AC-%E1%8B%A8%E1%8B%88%E1%8C%A3%E1%88%81%E1%89%B5-%E1%8A%A8%E1%8D%8D%E1%89%B6%E1%8A%9D-%E1%89%B0%E1%8C%88%E1%8D%8D%E1%89%BC-%E1%8A%90%E1%8B%8D-%E1%88%B5%E1%88%98%E1%88%88%E1%88%B5-%E1%8C%8D%E1%8A%95-%E1%89%82%E1%88%9D-%E1%8B%AD%E1%8B%A4-%E1%8A%A0%E1%8B%AD%E1%8B%B0%E1%88%88%E1%88%9D-%E1%89%B3%E1%88%9B%E1%8A%9D-%E1%89%A0%E1%8B%A8%E1%8A%90/a-45320852",
    },
    {
      title: "A Sharp Eye On Ethiopia",
      description:
        "After three decades as a prominent reporter in Ethiopia, the arrests and jailings -- punishment for articles deemed critical of the government -- became too much for Mulugeta Lule. He fled his country and now works for a District parking company.",
      image:
        "https://www.washingtonpost.com/pbox.php?url=https://www.washingtonpost.com/pb/resources/img/twp-social-share.png\u0026w=1484\u0026op=resize\u0026opt=1\u0026filter=antialias\u0026t=20170517",
      url: "https://www.washingtonpost.com/archive/local/2007/02/06/a-sharp-eye-on-ethiopia-span-classbankheadnva-network-to-provide-outlet-for-views-suppressed-in-homelandspan/11683838-56b4-4d61-b3f2-a3de6e7c1062/",
    },
    {
      title: "Tamagne Beyene",
      description:
        "Tamagne Beyene is a multi-talented performer, human rights activist, political commentator, talk show host and comedian. Tamagne was born in…",
      image:
        "https://www.ethiopiatrustfund.org/wp-content/uploads/2018/09/tamagn-e1536483225787.jpg",
      url: "https://www.ethiopiatrustfund.org/team/tamagne-beyene/",
    },
    {
      title:
        "tamagne beyene – The Ethiopian Satellite Television and Radio (ESAT)",
      description:
        "by Engidu Woldie ESAT News (September 3, 2018) Tamagne Beyene, Ethiopia’s leading rights activist, returned home after two decades in exile in the United States. Mr. Beyene has been a vocal critic of the ethnic oriented and divisive rule by the Tigray People’s Liberation Front (TPLF), an ethnic oligarchy that controlled political power with an iron fist for over a ...",
      image:
        "https://127702.a2cdn1.secureserver.net/wp-content/uploads/2012/07/ESAT-falvio.png",
      url: "https://ethsat.com/tag/tamagne-beyene/",
    },
    {
      title:
        "A Hug and Forgiveness: the Powerful Message of PM Abiy Ahmed and Tamagn Beyene",
      description:
        '"...what I witnessed today during Prime Minister Abiy Ahmed’s rally at the Convention Center was a powerful reminder that love is greater than vengeance..."',
      image:
        "https://borkena.com/wp-content/uploads/2018/07/Abiy-AHmed-and-Tamage-_.jpg",
      url: "https://borkena.com/2018/07/30/a-hug-and-forgiveness-the-powerful-message-of-pm-abiy-ahmed-and-tamagn-beyene/",
    },
    {
      title: "President Mulatu Receives Tamagn Beyene",
      description:
        "President Mulatu Teshome receives Ethiopian human rights activist Tamagn Beyene, who have been in exile for 22 years, at the National Palace today. The President conferred with Tamagn on the ongoing political reforms in Ethiopia",
      image:
        "https://waltainfo.com/wp-content/uploads/images/3-750x430356546.jpg",
      url: "https://waltainfo.com/41146/",
    },
    {
      title: "President Mulatu Receives Human Rights Activist Tamagn Beyene",
      description:
        "Addis Ababa September 03/2018 President Mulatu Teshome receives Ethiopian human rights activist Tamagn Beyene, who have been in exile for 22 years, at the National Palace today. The President conferred with Tamagn on the ongoing political reforms in Ethiopia and on how to take part in supporting government's endeavors to sustain peace and development in the country. During the discussion, President Mulatu said the government is committed to support Tamagn’s constructive move towards upholding the reform. Likewise, Tamagn has held discussion with Deputy Prime Minister, Demeke Mekonen on wide range of national issues and the current reforms in Ethiopia last Saturday. Tamagn Beyene has commended the ongoing reforms that have been widening the political space for people with different ideological perspectives. On the other hand, he condemned the mob justice on suspected criminals, noting that mob justice has to stop and freedom doesn’t mean anarchism. Any criminal should be penalized...",
      image:
        "https://www.ena.et/documents/42142/0/2018_09_tamagne-with-president_png.png/7ded52b4-41db-e55f-c364-11d63001a835?version=1.0\u0026t=1674399201645\u0026download=true",
      url: "https://www.ena.et/web/eng/w/en_2718",
    },
    {
      title:
        "Artist and activist Tamagn Beyene welcomed in Dire Dawa | Dire Dawa Administration Government Communication Affairs Bureau",
      description:
        "Artist and activist tamagn beyene got warm welcome by the community, stalk holders and government officials administration at Dire Dawa international airport.",
      image: "http://197.156.116.33/wp-content/uploads/2018/12/english7.jpg",
      url: "http://197.156.116.33/en/artist-and-activist-tamagn-beyene-welcomed-in-dire-dawa/",
    },
  ];

  return (
    <div className="grid  md:grid-cols-4">
      <div
        className="w-full bg-contain bg-fixed bg-no-repeat "
        style={{
          backgroundImage: `url(${tamagn}`,
        }}
      ></div>
      <div className="col-span-3">
        <div className="grid  md:grid-cols-2 lg:grid-cols-3 gap-2 m-10">
          {liftOfNews.map((each) => (
            <a
              href={each.url}
              target="blank"
              className="rounded-md shadow-md bg-gray-800 hover:bg-gray-700 duration-300 m-2 overflow-hidden"
            >
              {" "}
              <img
                className="w-full h-40 overflow-hidden"
                src={each.image}
                alt=""
              />
              <p className="px-3 h-7 overflow-hidden font-semibold">
                {each.title}
              </p>
              <p className="px-3 text-sm mb-3 h-16 overflow-hidden">
                {each.description}
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsPage;
