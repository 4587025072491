import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import HorizontalTimline from "./components/HorizontalTimline";

function App() {
  return (
    <div className="bg-gray-800">
      <Router>
        <Routes>
          {/* <Route element={<Home />} path="/" /> */}
          <Route element={<Navigate to="/home" />} path="/" />
          <Route element={<Home />} path="/:hashTag" />
        </Routes>
      </Router>
      <HorizontalTimline />
    </div>
  );
}

export default App;
